import { DoneInvokeEvent, MachineConfig } from 'xstate/lib/types';
import { assign } from 'xstate';
import { adviseReducer, TPaymentEvents } from './PaymentEvents';
import { Context, TGuards } from '../../Machines/sendPackageMachine';
import { Address } from '../../Models/Address';
import { Advise } from '../../Models/Advise';
import { AdviseMethod } from '../../Models/jena/request/BookDefRequest';
import { validEmail } from '../../helpers/validators';
import { ListCity } from '../../Models/jena/response/CreateGetCityInfoResponse';
import { StartEmbeddedResponse } from '../../Models/jena/response/StartEmbeddedResponse';
import { GetTokenResponse } from '../../Models/jena/response/GetTokenResponse';
import DeliveryOption from '../../Models/DeliveryOption';
import { Campaign, CampaignAlternative } from '../../Models/Campaign';
import { PackageInformationContext } from '../PackageInformation/packageInformationMachine';
import { getInsurancePrice } from '../../helpers/getInsurancePrice';
import { IPrice } from '../../Models/IPrice';
import { BookDefResponse } from '../../Models/jena/response/BookDefResponse';
import virtualPageview from '../../virtualPageview';

export interface PaymentPageContext {
  awbNumber: string;
  billingName: string;
  billingStreet: string;
  billingEmail: string;
  billingPostalCode: string;
  billingCountry: string;
  billingCity: string;
  billingPhone: string;
  paymentType: 'invoice' | 'card';
  paymentReference?: string;
  acceptedTerms: boolean;
  acceptPrivacyPolicy: boolean;
  orderConfirmation?: BookDefResponse;
  shipper: Address;
  advise: Advise[];
  netsToken?: string;
  netsCk?: string;
  netsPaymentId?: string;
  deliveryOption?: DeliveryOption;
  price?: IPrice;
  campaignCode?: string;
  campaigns?: Campaign[];
}

export interface NetsPaymentCompletedResponse {
  paymentId: string;
}

export enum PaymentPageGuards {
  invalidAddress = 'invalidBillingAddress',
  invalidCity = 'invalidBillingCity',
  invalidCountry = 'invalidBillingCountry',
  invalidEmail = 'invalidBillingEmail',
  invalidName = 'invalidBillingName',
  invalidPostalCode = 'invalidBillingPostalCode',
  invalidPaymentType = 'invalidPaymentType',
  invalidPaymentReference = 'invalidPaymentReference',
  invalidAdvise = 'invalidAdvise',
  invalidInvoiceNotLoggedIn = 'invalidInvoiceNotLoggedIn',
  invalidInvoiceNotAllowed = 'invalidInvoiceNotAllowed',
  notAcceptedTerms = 'notAcceptedTerms',
  notAcceptedPrivacy = 'notAcceptedPrivacy',
  invalidCampaigCode = 'invalidCampaignCode',
  validPayment = 'validPayment',
  pickupMissed = 'pickupMissed',
  invalidBillingPhone = 'invalidBillingPhone',
}
const inputStates = (invalidGuard: PaymentPageGuards) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
    success: {},
  },
});

export const paymentPageMachine: MachineConfig<
  Context & PaymentPageContext & PackageInformationContext,
  any,
  TPaymentEvents
> = {
  id: 'payment',
  initial: 'init',
  states: {
    init: {
      always: {
        cond: PaymentPageGuards.pickupMissed,
        target: '#payment.error.pickupMissed',
      },
      entry: [
        () => virtualPageview('/payment', 'Payment'),
        assign({
          billingCountry: (context) =>
            context.billingCountry || context.shipper.countryCode || '',
        }),
        assign({
          price: (context) => {
            const insurancePrice = getInsurancePrice(
              context.insuranceValue,
              context.insurance
            );
            return {
              priceexvat:
                (context.deliveryOption?.OriginalPriceExcludingVAT || 0) +
                insurancePrice.priceexvat,
              priceincvat:
                (context.deliveryOption?.OriginalPriceIncludingVAT || 0) +
                insurancePrice.priceincvat,
            };
          },
          campaignCode: (_) => undefined,
          campaigns: (_) => undefined,
          acceptedTerms: (ctx) =>
            ctx.acceptedTerms ||
            (ctx.userLoggedIn &&
              localStorage.getItem(`acceptedTerms_${ctx.userId}`) === 'true'),
          acceptPrivacyPolicy: (ctx) =>
            ctx.acceptPrivacyPolicy ||
            (ctx.userLoggedIn &&
              localStorage.getItem(`acceptPrivacyPolicy_${ctx.userId}`) ===
                'true'),
          acceptedAirlineTerms: (ctx, e) =>
            ctx.acceptedAirlineTerms ||
            (ctx.userLoggedIn &&
              ctx.deliveryOption?.MethodOfTransport !== 'Flight' &&
              localStorage.getItem(`acceptedAirlineTerms_${ctx.userId}`) ===
                'true'),
        }),
        'scrollToTop',
      ],
      invoke: {
        id: 'createAwb',
        src: 'createAwb',
        onDone: {
          actions: assign({
            awbNumber: (_, event) => event.data,
          }),
          target: 'editing',
        },
        onError: 'error.createAwb',
      },
    },
    error: {
      states: {
        createAwb: {},
        cancelled: { on: { ERROR_OK: '#payment.editing' } },
        declined: { on: { ERROR_OK: '#payment.editing' } },
        pickupMissed: { on: { ERROR_OK: '#delivery.init' } },
        order: {
          on: { ERROR_OK: '#payment.editing' },
        },
      },
      on: {
        ERROR_OK: 'editing',
      },
    },
    editing: {
      type: 'parallel',
      states: {
        address: inputStates(PaymentPageGuards.invalidAddress),
        city: inputStates(PaymentPageGuards.invalidCity),
        country: inputStates(PaymentPageGuards.invalidCountry),
        email: inputStates(PaymentPageGuards.invalidEmail),
        name: inputStates(PaymentPageGuards.invalidName),
        postalCode: inputStates(PaymentPageGuards.invalidPostalCode),
        paymentType: {
          initial: 'init',
          states: {
            init: {},
            edit: {
              always: [
                {
                  cond: PaymentPageGuards.invalidInvoiceNotLoggedIn,
                  target: 'error.notLoggedIn',
                },
                {
                  cond: PaymentPageGuards.invalidInvoiceNotAllowed,
                  target: 'error.notAllowed',
                },
                {
                  cond: PaymentPageGuards.invalidPaymentType,
                  target: 'error.invalidType',
                },
                { target: 'valid' },
              ],
            },
            valid: {},
            error: {
              states: {
                invalidType: {},
                notLoggedIn: {},
                notAllowed: {},
              },
            },
          },
        },
        advise: inputStates(PaymentPageGuards.invalidAdvise),
        paymentReference: inputStates(
          PaymentPageGuards.invalidPaymentReference
        ),
        campaignCode: inputStates(PaymentPageGuards.invalidCampaigCode),
        terms: inputStates(PaymentPageGuards.notAcceptedTerms),
        privacy: inputStates(PaymentPageGuards.notAcceptedPrivacy),
      },
      on: {
        CHANGE_ADVISE: {
          actions: assign({
            advise: (ctx, { data }) => adviseReducer(ctx.advise, data),
          }),
          target: '#payment.editing.advise.edit',
        },
        CHANGE_BILLING_ADDRESS: {
          actions: assign({
            billingStreet: (_, { data }) => data,
          }),
          target: '#payment.editing.address.edit',
        },
        CHANGE_BILLING_CITY: {
          actions: assign({
            billingCity: (_, { data }) => data,
          }),

          target: '#payment.editing.city.edit',
        },
        CHANGE_BILLING_COUNTRY: {
          actions: assign({
            billingCountry: (_, { data }) => data,
          }),

          target: '#payment.editing.country.edit',
        },
        CHANGE_BILLING_EMAIL: {
          actions: assign({
            billingEmail: (_, { data }) => data,
          }),

          target: '#payment.editing.email.edit',
        },
        CHANGE_BILLING_PHONE: {
          actions: assign({
            billingPhone: (_, { data }) => data,
          }),

          target: '#payment.editing.email.edit',
        },
        CHANGE_BILLING_NAME: {
          actions: assign({
            billingName: (_, { data }) => data,
          }),

          target: '#payment.editing.name.edit',
        },
        CHANGE_BILLING_POSTALCODE: {
          actions: assign({
            billingPostalCode: (_, { data }) => data,
          }),

          target: '#payment.editing.postalCode.edit',
        },
        CHANGED_BILLING_POSTALCODE: [
          { cond: PaymentPageGuards.invalidPostalCode },
          {
            target: '#payment.loadCity',
          },
        ],
        CHANGE_PAYMENT_TYPE: {
          actions: assign({
            paymentType: (_, { data }) => data,
          }),

          target: '#payment.editing.paymentType.edit',
        },
        CHANGE_PAYMENT_REFERENCE: {
          actions: assign({
            paymentReference: (_, { data }) => data,
          }),

          target: '#payment.editing.paymentReference.edit',
        },
        CHANGE_CAMPAIGN_CODE: {
          actions: assign({
            campaignCode: (_, { data }) => data,
          }),

          target: '#payment.editing.campaignCode.edit',
        },
        VERIFY_CAMPAIGN_CODE: {
          target: '#payment.campaignCode',
        },
        CHANGE_ACCEPT_TERMS: {
          actions: assign({
            acceptedTerms: (_, { data }) => data,
          }),
          target: '#payment.editing.terms.edit',
        },
        CHANGE_PRIVACY_POLICY: {
          actions: assign({
            acceptPrivacyPolicy: (_, { data }) => data,
          }),
          target: '#payment.editing.privacy.edit',
        },
        START_ORDER: [
          {
            cond: PaymentPageGuards.pickupMissed,
            target: '#payment.error.pickupMissed',
          },
          {
            cond: PaymentPageGuards.validPayment,
            target: 'startOrder',
            actions: 'saveAcceptance',
          },
          {
            target: [
              '#payment.editing.privacy.edit',
              '#payment.editing.country.edit',
              '#payment.editing.city.edit',
              '#payment.editing.email.edit',
              '#payment.editing.name.edit',
              '#payment.editing.address.edit',
              '#payment.editing.postalCode.edit',
              '#payment.editing.paymentType.edit',
              '#payment.editing.paymentReference.edit',
              '#payment.editing.terms.edit',
              '#payment.editing.privacy.edit',
              '#payment.editing.advise.edit',
            ],
          },
        ],
        NETS_PAY_INITIALIZED: {
          actions(context, event, meta) {
            console.log('NETS_PAY_INITIALIZED', event);
          },
        },
      },
    },
    loadCity: {
      invoke: {
        id: 'getCityInfo',
        src: 'getCityInfo',
        onDone: [
          {
            cond: (ctx, e) => e.data?.length,
            actions: [
              assign((ctx, e: DoneInvokeEvent<ListCity[]>) => ({
                billingCity: e.data?.length ? e.data[0].cityname : '',
              })),
            ],
            target: '#payment.editing.city.edit',
          },
          { target: '#payment.editing.city.edit' },
        ],
      },
    },
    startOrder: {
      entry: [() => console.log('startorder')],
      always: [
        { cond: (ctx) => ctx.paymentType === 'card', target: 'cardPayment' },
        'processing',
      ],
    },
    campaignCode: {
      initial: 'init',
      states: {
        init: {
          invoke: {
            id: 'verifyCampaignCode',
            src: 'verifyCampaignCode',
            onDone: [
              {
                cond: (ctx, e: DoneInvokeEvent<Campaign[]>) =>
                  e.data.length > 0,
                actions: [
                  assign({
                    campaigns: (ctx, e: DoneInvokeEvent<Campaign[]>) => e.data,
                    price: (ctx, e: DoneInvokeEvent<Campaign[]>) => {
                      const insurancePrice = getInsurancePrice(
                        ctx.insuranceValue,
                        ctx.insurance
                      );
                      for (const c of e.data) {
                        const alternative: CampaignAlternative | undefined =
                          c.alternatives.find(
                            (a) =>
                              a.alternativeId ===
                              ctx.deliveryOption?.AlternativeId
                          );
                        if (alternative) {
                          return {
                            priceexvat:
                              Math.round(
                                (alternative.priceExVat +
                                  insurancePrice.priceexvat) *
                                  100
                              ) / 100,
                            priceincvat:
                              Math.round(
                                (alternative.priceIncVat +
                                  insurancePrice.priceincvat) *
                                  100
                              ) / 100,
                          };
                        }
                      }
                      return {
                        priceexvat:
                          Math.round(
                            ((ctx.deliveryOption?.OriginalPriceExcludingVAT ||
                              0) +
                              insurancePrice.priceexvat) *
                              100
                          ) / 100,
                        priceincvat:
                          Math.round(
                            ((ctx.deliveryOption?.OriginalPriceIncludingVAT ||
                              0) +
                              insurancePrice.priceincvat) *
                              100
                          ) / 100,
                      };
                    },
                  }),
                ],
                target: '#payment.editing.campaignCode.success',
              },
              {
                target: '#payment.editing.campaignCode.error',
              },
            ],
            onError: {
              target: '#payment.editing.campaignCode.error',
            },
          },
        },
      },
    },
    cardPayment: {
      initial: 'init',
      states: {
        init: {
          invoke: {
            id: 'getToken',
            src: 'getToken',
            onDone: {
              actions: [
                assign({
                  netsToken: (ctx, e: DoneInvokeEvent<GetTokenResponse>) =>
                    e.data.token,
                }),
              ],
              target: 'start',
            },
          },
        },
        start: {
          invoke: {
            id: 'startEmbedded',
            src: 'startEmbedded',
            onDone: {
              target: 'netsCheckout',
              actions: [
                assign({
                  netsCk: (ctx, e: DoneInvokeEvent<StartEmbeddedResponse>) =>
                    e.data.CK,
                  netsPaymentId: (
                    ctx,
                    e: DoneInvokeEvent<StartEmbeddedResponse>
                  ) => e.data.PAYMENTID,
                }),
              ],
            },
          },
        },
        netsCheckout: {},
      },
      on: {
        CARD_ACCEPTED: {
          actions: assign({
            netsPaymentId: (_, { data }) => data.paymentId,
          }),
          target: 'processing',
        },
        CARD_CANCELLED: {
          target: 'error.cancelled',
        },
        CARD_DECLINED: {
          target: 'error.declined',
        },
      },
    },
    processing: {
      invoke: {
        id: 'createOrder',
        src: 'createOrder',
        onDone: {
          target: 'success',
          actions: [
            'scrollToTop',
            assign({
              orderConfirmation: (_, event) => event.data,
            }),
          ],
        },
        onError: 'error.order',
      },
    },
    success: {
      always: '#orderConfirmation',
    },
  },
  on: {
    ORDER_CREATED: 'success',
  },
};

export const validAdvise = (advise: Advise) => {
  let input;
  switch (advise.method) {
    case AdviseMethod.Email:
      input = advise.value ?? '';
      return input.length === 0 || validEmail(input) ? true : false;
    case AdviseMethod.Sms:
      // TODO: validate phone numbers?
      // input = advise.value?.length ?? 0;
      // return input > 4 || input === 0 ? true : false;
      return true;
    default:
      return false;
  }
};

const minLength = (val?: string, min: number = 2) => !!val && val.length > min;

export const PaymentGuards: TGuards<PaymentPageGuards> = {
  invalidBillingAddress: (ctx) =>
    ctx.paymentType === 'card' && !minLength(ctx.billingStreet),

  invalidBillingCity: (ctx) =>
    ctx.paymentType === 'card' && !minLength(ctx.billingCity),

  invalidBillingCountry: (ctx) =>
    ctx.paymentType === 'card' && ctx.billingCountry?.length !== 2,

  invalidBillingEmail: (ctx) =>
    ctx.paymentType === 'card' && !validEmail(ctx.billingEmail),

  invalidBillingPhone: (ctx) =>
    ctx.paymentType === 'card' && !minLength(ctx.billingPhone),

  invalidBillingName: (ctx) =>
    ctx.paymentType === 'card' && !minLength(ctx.billingName),

  invalidBillingPostalCode: (ctx) =>
    ctx.paymentType === 'card' && !minLength(ctx.billingPostalCode),

  invalidPaymentReference: (ctx) => !minLength(ctx.paymentReference, 0),

  // TODO: we need to check if isinvoiceallowed from getconsignmentinfo
  invalidPaymentType: (ctx, event, meta) =>
    !['invoice', 'card'].find((k) => k === ctx.paymentType) ||
    PaymentGuards.invalidInvoiceNotLoggedIn(ctx, event, meta) ||
    PaymentGuards.invalidInvoiceNotAllowed(ctx, event, meta),

  // make sure pickup time is in the future
  pickupMissed: (ctx) =>
    ctx.deliveryOption
      ? ctx.deliveryOption?.PickupDateTime < new Date()
      : false,

  invalidInvoiceNotLoggedIn: (ctx) =>
    ctx.paymentType === 'invoice' && !ctx.userLoggedIn,
  invalidInvoiceNotAllowed: (ctx) =>
    ctx.paymentType === 'invoice' && !ctx.invoiceAllowed,
  invalidAdvise: (ctx) => {
    return !ctx.advise.reduce((acc: boolean, a) => {
      const requiredEmailIsValid = a.required
        ? a.value?.length && validEmail(a.value)
          ? true
          : false
        : true;
      return acc && validAdvise(a) && requiredEmailIsValid;
    }, true);
  },

  invalidCampaignCode: (ctx) => false,

  notAcceptedPrivacy: (ctx) => !ctx.acceptPrivacyPolicy,
  notAcceptedTerms: (ctx) => !ctx.acceptedTerms,
  validPayment: (context, event, meta) =>
    [
      // PaymentGuards.invalidBillingAddress,
      // PaymentGuards.invalidBillingCity,
      // PaymentGuards.invalidBillingCountry,
      // PaymentGuards.invalidBillingName,
      // PaymentGuards.invalidBillingPostalCode,
      PaymentGuards.invalidPaymentReference,
      PaymentGuards.invalidPaymentType,
      PaymentGuards.invalidAdvise,
      PaymentGuards.notAcceptedPrivacy,
      PaymentGuards.notAcceptedTerms,
      PaymentGuards.invalidBillingPhone,
      PaymentGuards.invalidBillingEmail,
    ].reduce((acc: boolean, fn) => acc && !fn(context, event, meta), true),
};
