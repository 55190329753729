/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import tw from 'twin.macro';
import { FormInput } from '../../Components/FormFields';
import { Button, FormGroup } from '../../Components/Base';
import { useMachine } from '@xstate/react';
import useJenaApi from '../../hooks/useJenaApi';
import LoginMachine, { LoginGuards } from '../../Pages/Login/LoginMachine';
import { UserContext } from '../../context/UserContext';
import { DoneInvokeEvent } from 'xstate';
import { User } from '../../Models/User';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import FormMessage from '../Base/FormMessage';
import { CountryPrefixedLink } from '../CountryPrefixedLink';

const Login = () => {
  const { userEvents } = useContext(UserContext);

  const api = useJenaApi();
  const [state, send] = useMachine(LoginMachine, {
    actions: {
      updateUser: (context, event) => {
        const { data } = event as DoneInvokeEvent<{
          user: User;
          sessionToken: string;
        }>;
        userEvents.loggedIn(data);
      },
    },
    services: {
      loginRequest: (context: any, event: any) => {
        return api.login(context.username, context.password);
      },
    },
  });
  const translation: Translations = useTranslation();

  return (
    <form>
      <FormGroup>
        <FormInput
          name="username"
          label={translation.username}
          value={state.context.username}
          error={state.matches('editing.username.error')}
          type="Text"
          onChange={(val) => {
            send({ type: 'CHANGE_USERNAME', data: val });
          }}
          autoComplete="username"
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="password"
          label={translation.password}
          value={state.context.password}
          error={state.matches('editing.password.error')}
          type="password"
          onChange={(val) => {
            send({ type: 'CHANGE_PASSWORD', data: val });
          }}
          autoComplete="current-password"
        />
      </FormGroup>

      {state.matches('failed') && (
        <FormGroup>
          <FormMessage status="error" label={translation.signInErrorMessage} />
        </FormGroup>
      )}

      <div css={tw`row`}>
        <div css={tw`col-6`}>
          <CountryPrefixedLink to="/reset-password" className="text-gray-600">
            {translation.forgotPassword}
          </CountryPrefixedLink>
        </div>
        <div css={tw`col-6`}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              send({ type: 'FETCH' });
            }}
            variant="secondary"
            disabled={
              state.matches('loading') ||
              // @ts-ignore
              LoginGuards.validLogin(state.context) === false
            }
            options={tw`ml-auto`}
          >
            {(state.matches('editing') || state.matches('failed')) &&
              translation.signIn}
            {state.matches('loading') && translation.loading}
            {state.matches('successful') && translation.signedIn}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Login;
