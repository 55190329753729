/** @jsxImportSource @emotion/react */
import { FormEvent, Fragment } from 'react';
import tw from 'twin.macro';
import { FormCheckbox, FormInput } from '../../Components/FormFields';
import { Button, FormGroup, TextButton, TextLink } from '../../Components/Base';
import useTranslation from '../../hooks/useTranslation';
import OrderSummary from './Components/OrderSummary';
import { SendPackageContext } from '../../Machines/sendPackageMachine';
import { AdviseEvents, PaymentEvents, TPaymentEvents } from './PaymentEvents';
import { CardPayment } from './Components/CardPayment';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import { StateValue } from 'xstate';
import { boxShadow } from '../../Components/Base/BoxShadow';
import { AdviseList } from './Components/AdviseList';
import { BillingInformation } from './Components/BillingInformation';
import Loader, { LoaderLabel } from '../../Components/Base/Loader';
import { CountryPrefixedLink } from '../../Components/CountryPrefixedLink';

interface PaymentPageProps {
  state: SendPackageContext;
  send: (event: TPaymentEvents) => void;
  matches: (key: StateValue) => boolean;
}
const PaymentPage = ({ state, send, matches }: PaymentPageProps) => {
  const events = useBoundEvents(PaymentEvents, send);
  const adviseEvents = useBoundEvents(AdviseEvents, events.changeAdvise);
  const translations = useTranslation();

  const onCampaignFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    events.verifyCampaignCode();
  };

  return (
    <Fragment>
      <OrderSummary state={state} />

      <div tw={'py-10 border-t bg-white'}>
        <div tw={'container mx-auto px-4'}>
          <div tw={'row'}>
            <div tw={'col-12 md:col-10 lg:col-8'}>
              <h2 tw={'font-heading font-semibold text-xl mb-2 leading-tight'}>
                {translations.advise.title}
              </h2>
              <p tw={'mb-6'}>{translations.advise.description}</p>
            </div>
          </div>
          <AdviseList
            advise={state.advise}
            events={adviseEvents}
            error={matches('editing.advise.error')}
          />
        </div>
      </div>

      {state.userLoggedIn ? (
        <div tw={'py-10 border-t'}>
          <div tw={'container mx-auto px-4'}>
            <div tw={'row'}>
              <div tw={'col-12 md:col-6'}>
                <h2 tw="font-heading font-semibold text-xl mb-2 leading-tight">
                  <p>{translations.paymentPage.campaignCode}</p>
                </h2>
              </div>
            </div>
            <div tw="row">
              <div tw={'col-12 md:col-8 lg:col-6'}>
                <form onSubmit={onCampaignFormSubmit}>
                  <FormGroup>
                    <FormInput
                      label={translations.paymentPage.campaignCode}
                      name="campaign-code"
                      placeholder={translations.paymentPage.campaignCode}
                      value={state.campaignCode || ''}
                      onChange={events.changeCampaignCode}
                      error={matches('editing.campaignCode.error')}
                      success={matches('editing.campaignCode.success')}
                    />
                  </FormGroup>
                  {matches('editing.campaignCode.success') && (
                    <p>{translations.paymentPage.campaignCodeApplied}</p>
                  )}
                  {matches('editing.campaignCode.error') && (
                    <p>{translations.paymentPage.invalidCampaignCode}</p>
                  )}
                </form>
              </div>
            </div>
            <div tw={'row'}>
              <div tw={'col-12'}>
                <div css={tw`w-full flex flex-wrap mt-2`}>
                  {matches('campaignCode') && (
                    <Loader>
                      <LoaderLabel label={translations.loading} />
                    </Loader>
                  )}
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={events.verifyCampaignCode}
                    css={[tw`w-auto rounded-full py-2 px-10 mr-2`]}
                  >
                    {translations.paymentPage.apply}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div tw={'py-10 border-t'}>
        <div tw={'container mx-auto px-4'}>
          <div tw={'row'}>
            <div tw={'col-12 md:col-6'}>
              <h2 tw="font-heading font-semibold text-xl mb-2 leading-tight">
                {translations.paymentPage.paymentOptions}
              </h2>
            </div>
          </div>
          <div tw="row">
            <div tw={'col-12 md:col-8 lg:col-6 mb-4'}>
              <FormGroup>
                <FormInput
                  label={translations.paymentPage.paymentReference}
                  name={'payment-reference'}
                  placeholder={
                    translations.paymentPage.paymentReferencePlaceholder
                  }
                  value={state.paymentReference || ''}
                  onChange={events.changePaymentReference}
                  error={matches('editing.paymentReference.error')}
                  errorMessage={translations.requiredField}
                  required={true}
                />
              </FormGroup>
            </div>
          </div>
          <div tw={'row'}>
            <div tw={'col-12 md:col-8 lg:col-6'}>
              <div tw="flex mt-4 mb-8">
                <Button
                  variant={state.paymentType === 'card' ? 'success' : 'outline'}
                  size="sm"
                  css={[tw`w-auto rounded-full py-2 px-10 mr-2`]}
                  onClick={() => events.changePaymentType('card')}
                >
                  {translations.creditcard}
                </Button>
                <Button
                  variant={
                    state.paymentType === 'invoice' ? 'success' : 'outline'
                  }
                  size="sm"
                  tw="w-auto rounded-full py-2 px-10"
                  onClick={() => events.changePaymentType('invoice')}
                >
                  {translations.invoice}
                </Button>
              </div>
            </div>
          </div>
          {matches('editing.paymentType.error') && (
            <div tw="row">
              <div tw="col-12 md:col-8 lg:col-6">
                <div
                  css={[
                    tw`p-6 mb-10 border-l-4 border-darkred bg-white`,
                    boxShadow,
                  ]}
                >
                  {matches('editing.paymentType.error.notAllowed') ? (
                    <div>{translations.paymentPage.invoiceNotAllowed}</div>
                  ) : (
                    <Fragment>
                      <span tw="block mb-1">
                        {translations.paymentPage.signIntoPayByInvoice}
                      </span>

                      <CountryPrefixedLink to="/login">
                        <TextButton>{translations.signIn}</TextButton>
                      </CountryPrefixedLink>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
          {state.paymentType === 'card' && (
            <BillingInformation
              state={state}
              events={events}
              matches={matches}
            />
          )}
          <div tw={'row mt-8'}>
            <div tw={'col-12'}>
              <FormGroup>
                <FormCheckbox
                  id="terms"
                  name="terms"
                  text={translations.termsAndPolicies.termsAndConditions.label}
                  checked={state.acceptedTerms}
                  onChange={events.changeAcceptedTerms}
                  error={matches('editing.terms.error')}
                  errorMessage={translations.requiredField}
                  required={true}
                >
                  <TextLink
                    href={translations.termsAndPolicies.termsAndConditions.url}
                    target="_blank"
                  >
                    {translations.termsAndPolicies.termsAndConditions.urlLabel}
                  </TextLink>
                </FormCheckbox>
              </FormGroup>
            </div>
            <div tw={'col-12'}>
              <FormGroup>
                <FormCheckbox
                  id="privacy_policy"
                  name="privacy_policy"
                  text={translations.termsAndPolicies.privacyPolicy.label}
                  checked={state.acceptPrivacyPolicy}
                  onChange={events.changePrivacyPolicy}
                  error={matches('editing.privacy.error')}
                  errorMessage={translations.requiredField}
                  required={true}
                >
                  <TextLink
                    href={translations.termsAndPolicies.privacyPolicy.url}
                    target="_blank"
                  >
                    {translations.termsAndPolicies.privacyPolicy.urlLabel}
                  </TextLink>
                </FormCheckbox>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <CardPayment state={state} events={events} matches={matches} />
    </Fragment>
  );
};

export default PaymentPage;
