/** @jsxImportSource @emotion/react */
import { useUser } from '../hooks/useUser';
import useTranslation from '../hooks/useTranslation';
import { useRef, useState } from 'react';
import tw from 'twin.macro';
import useClickOutsideHandler from '../hooks/useClickOutsideHandler';
import { IconChevronDown } from '../svg';
import { CountryPrefixedLink } from './CountryPrefixedLink';

export const LoginLogout = () => {
  const { isLoggedIn, logout, user } = useUser();
  const translations = useTranslation();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutsideHandler(ref, () => setShowUserMenu(false));

  return !isLoggedIn ? (
    <>
      <li>
        <CountryPrefixedLink to="/login">
          {translations.signIn}
        </CountryPrefixedLink>
      </li>
      <li>
        <CountryPrefixedLink to="/register">
          {translations.register}
        </CountryPrefixedLink>
      </li>
    </>
  ) : (
    <li>
      <div className="relative" ref={ref}>
        <button
          onClick={() => setShowUserMenu(!showUserMenu)}
          css={[tw`flex items-center`]}
        >
          {`${user?.firstName || ''} ${user?.lastName || ''} `}
          <IconChevronDown tw="w-5 h-6" />
        </button>
        <div
          css={[
            tw`absolute right-0 top-6 border rounded flex flex-col bg-white z-30 shadow min-w-full`,
            showUserMenu ? '' : tw`hidden`,
          ]}
        >
          <CountryPrefixedLink
            to="/login"
            css={[tw`flex items-center space-x-2 p-2 px-3 hover:bg-gray-100`]}
          >
            {translations.profile}
          </CountryPrefixedLink>
          <CountryPrefixedLink
            to="/change-password"
            css={[tw`flex items-center space-x-2 p-2 px-3 hover:bg-gray-100`]}
          >
            {translations.changePassword}
          </CountryPrefixedLink>
          <CountryPrefixedLink
            to="/"
            onClick={() => logout()}
            css={[tw`flex items-center space-x-2 p-2 px-3 hover:bg-gray-100`]}
          >
            {translations.signOut}
          </CountryPrefixedLink>
        </div>
      </div>
    </li>
  );
};
