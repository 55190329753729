/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import { Logo } from '../../svg';
import Login from '../../Components/Login/Login';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import { Fragment } from 'react';
import { CountryPrefixedLink } from '../../Components/CountryPrefixedLink';
const LoginPage = () => {
  const translations: Translations = useTranslation();
  return (
    <Fragment>
      <div css={tw`row`}>
        <div css={tw`col-12`}>
          <Logo
            css={[
              css`
                max-width: 25%;
              `,
              tw`mb-4 mx-auto`,
            ]}
          />
          <h2
            css={tw`font-heading font-semibold text-3xl mb-4 leading-tight text-center`}
          >
            {translations.loginPage.title}
          </h2>
        </div>
      </div>
      <div css={tw`row max-w-xl`}>
        <div tw={'col-12'}>
          <Login />
        </div>
        <div tw={'col-12'}>
          <p tw="my-2 text-center flex text-gray-500 after:border-b after:border-gray-300 after:content[''] after:m-auto after:flex-1 after:ml-4 before:border-b before:border-gray-300 before:content[''] before:m-auto before:flex-1 before:mr-4">
            {translations.or}
          </p>
          <div tw="flex justify-center">
            <CountryPrefixedLink
              to="/register"
              tw="block p-2 px-4 border hover:bg-gray-300"
            >
              {translations.register}
            </CountryPrefixedLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginPage;
