import { useParams, Link, LinkProps } from 'react-router-dom';

export const CountryPrefixedLink = ({ to, children, ...rest }: LinkProps) => {
  const { country } = useParams<{ country: string }>();
  return (
    <Link to={`${country ? `/${country}` : ''}${to}`} {...rest}>
      {children}
    </Link>
  );
};
